import React from "react";
import styled from "styled-components";
import { rem, vwDesktop, vwMobile } from "../../../helpers/styles";
import { MEDIA_DESKTOP } from "../../../helpers/theme";
import { Image } from "../../../types";
import { ROUTES } from "../../../helpers/routes";
import { transformISOtoText } from "../../../helpers/isoDuration";
import { useStaticLabels } from "../../../hooks/useStaticLabels";
import Link from "../../shared/Link";

export type RecipeItemProps = RecipeItemColors & {
  content: RecipeItem;
  searchkey?: string;
};

export type RecipeItemLabels = {
  servesLabel: string;
  prepTimeLabel: string;
  cookTimeLabel: string;
};

export type RecipeItem = {
  bottomLeftLabel: string;
  bottomLeftValue: string;
  bottomMidLabel: string;
  bottomMidValue: string;
  bottomRightLabel: string;
  bottomRightValue: string;
  image: Image;
  subtitle: string;
  title: string;
  recipeDetail: {
    recipeDetailSlug: string;
  };
  tags?: [];
};

export type RecipeItemColors = {
  bgColor?: string;
  labelColor: string;
  textColor: string;
  hoverTextColor?: string;
  hoverBgColor?: string;
};

export function RecipeCard(props: RecipeItemProps): JSX.Element {
  const { textColor, labelColor, content, searchkey } = props;
  const labels = useStaticLabels();

  return (
    <StyledLink
      to={`${ROUTES.RECIPE_DETAIL}/${content?.recipeDetail?.recipeDetailSlug}${
        searchkey ? `?q=${searchkey}` : ``
      }`}
    >
      <Container {...props}>
        <div className="bg" />
        <div className="content">
          <img src={content?.image?.src} alt={content?.image?.alt} />
          <Heading>
            <Subtitle className="subtitle" {...{ labelColor }}>
              {content?.subtitle}
            </Subtitle>
            <Title {...{ textColor }}>{content?.title}</Title>
          </Heading>
          <Details>
            <div>
              <span>{content?.bottomLeftLabel}</span>
              <strong>{content?.bottomLeftValue}</strong>
            </div>
            <div>
              <span>{content?.bottomMidLabel}</span>
              <strong>
                {transformISOtoText(
                  content?.bottomMidValue,
                  labels.minutes,
                  labels.hour,
                  labels.hours
                )}
              </strong>
            </div>
            <div>
              <span>{content?.bottomRightLabel}</span>
              <strong>
                {transformISOtoText(
                  content?.bottomRightValue,
                  labels.minutes,
                  labels.hour,
                  labels.hours
                )}
              </strong>
            </div>
          </Details>
        </div>
      </Container>
    </StyledLink>
  );
}

export default RecipeCard;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  height: ${vwMobile(153)};
  margin-bottom: ${vwMobile(26)};

  ${MEDIA_DESKTOP} {
    height: ${vwDesktop(153)};
    margin-bottom: ${vwDesktop(26)};
  }
`;

const Subtitle = styled.div<{ labelColor: string }>`
  margin-bottom: ${vwMobile(15)};
  color: ${(p) => p.labelColor};
  font-size: ${rem(18)};
  font-weight: 800;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  line-height: ${rem(24)};
  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(16)};
  }
`;

const Title = styled.div<{ textColor: string }>`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: ${rem(24)};
  font-weight: 900;
  letter-spacing: 0.06em;
  line-height: ${rem(31)};
  text-transform: uppercase;
`;

const Details = styled.div`
  display: flex;
  gap: ${vwMobile(24)};

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(20)};
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: ${vwMobile(5)};
    flex: 1 0 ${vwDesktop(80)};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    ${MEDIA_DESKTOP} {
      gap: ${vwDesktop(7)};
      width: ${vwDesktop(100)};
    }

    span {
      font-size: ${rem(14)};
      font-weight: 600;
      line-height: ${rem(18)};
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    strong {
      font-size: ${rem(18)};
      font-weight: 800;
      letter-spacing: 0.08em;
      line-height: ${rem(24)};
      text-transform: uppercase;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

const Container = styled.article<RecipeItemColors>`
  position: relative;
  width: ${vwMobile(337)};

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(400)};
    height: ${vwDesktop(624)};
  }

  &:hover {
    ${Heading} {
      background-color: ${(p) => p.hoverBgColor ?? p.bgColor ?? "transparent"};
      color: ${(p) => p.hoverTextColor ?? p.textColor ?? "white"};
    }
    ${Details} {
      background-color: ${(p) => p.hoverBgColor ?? p.bgColor ?? "transparent"};
      color: ${(p) => p.hoverTextColor ?? p.textColor ?? "white"};
    }
    .bg {
      background-color: ${(p) => p.hoverBgColor ?? p.bgColor ?? "transparent"};
    }
    .content {
      color: ${(p) => p.hoverTextColor ?? p.textColor ?? "white"};

      .subtitle {
        color: ${(p) => p.hoverTextColor ?? p.labelColor ?? "white"} !important;
      }
    }

    ${MEDIA_DESKTOP} {
      .bg {
        height: ${vwDesktop(464)};
      }
    }
  }

  .bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${vwMobile(485)};
    background-color: ${(p) => p.bgColor ?? "transparent"};
    z-index: 1;
    transition: all 0.3s;

    ${MEDIA_DESKTOP} {
      height: ${vwDesktop(544)};
    }
  }

  ${Heading} {
    background-color: ${(p) => p.bgColor ?? "transparent"};
    color: ${(p) => p.textColor ?? "white"};
    transition: all 0.3s;
  }

  ${Details} {
    background-color: ${(p) => p.bgColor ?? "transparent"};
    color: ${(p) => p.textColor ?? "white"};
    transition: all 0.3s;
  }

  .content {
    padding: 0 ${vwMobile(20)} ${vwMobile(22)};
    display: flex;
    flex-direction: column;
    color: ${(p) => p.textColor ?? "white"};
    position: relative;
    z-index: 2;
    transition: all 0.3s;

    ${MEDIA_DESKTOP} {
      padding: 0 ${vwDesktop(24)} ${vwDesktop(26)};
    }
  }

  img {
    align-self: center;
    width: ${vwMobile(297)};
    height: ${vwMobile(297)};
    margin-bottom: ${vwMobile(28)};
    object-fit: cover;

    ${MEDIA_DESKTOP} {
      width: ${vwDesktop(352)};
      height: ${vwDesktop(352)};
      margin-bottom: ${vwDesktop(28)};
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;
